var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-window", {
        on: {
          "emit-component-name": _vm.emitComponentName,
          "emit-item": _vm.emitItem,
        },
      }),
      _c("v-divider"),
      _c("history-window", {
        on: {
          "emit-component-name": _vm.emitComponentName,
          "emit-item": _vm.emitItem,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }