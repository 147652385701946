var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-1 mx-1 mb-4", attrs: { color: "grey lighten-3" } },
    [
      _c("v-card-title", [_vm._v("検索を開始する")]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex justify-center pb-2",
              staticStyle: { "font-size": "1.2em" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "10", md: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "indigo darken-1",
                        width: "100%",
                        height: "100px",
                      },
                      on: { click: _vm.emitComponentName },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-magnify")]),
                      _vm._v(" 形状から検索 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "10", md: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "indigo darken-1",
                        width: "100%",
                        height: "100px",
                        disabled: "",
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-magnify")]),
                      _vm._v(" 規格から検索 "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "10", md: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "indigo darken-1",
                        width: "100%",
                        height: "100px",
                        disabled: "",
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-magnify")]),
                      _vm._v(" キーワードから検索 "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }