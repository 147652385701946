var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-1 mx-1 my-4", attrs: { color: "grey lighten-3" } },
    [
      _c("v-card-title", [_vm._v("最近閲覧した商品")]),
      _c(
        "v-card-text",
        [
          _c("carousel-button", {
            attrs: { headerIsOn: false, inputItems: _vm.itemHistory },
            on: { "update-query": _vm.accessResultPage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }